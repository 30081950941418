import { createContext, FC, useContext, useEffect, useMemo, useState } from 'react';

import * as Sentry from '@sentry/react';
import { v4 } from 'uuid';

import { ApplicantNO, ApplicantSE, Country, OptionalApplicantNO, OptionalApplicantSE } from 'src/api/zrm';
import useWebsiteVisitCallback from 'src/customerPages/tracking/useWebsiteVisitCallback';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';
import { parseDate } from 'src/utils/parseDate';

type GrApplicantType =
  (ApplicantSE | ApplicantNO)
  | (OptionalApplicantSE | OptionalApplicantNO)
  | ((ApplicantSE | ApplicantNO) & (OptionalApplicantSE | OptionalApplicantNO));

export interface CustomerDataProps {
  dataLoading: boolean;
  getData: (requestId?: string) => void;
  data: GrApplicantType;
  setData?: React.Dispatch<React.SetStateAction<GrApplicantType>>;
  dataLoaded: boolean;
  lastExternallyVerifiedCredits?: Date;
  externallyVerifiedCredits?: ApplicantNO['externally_verified_credits'];
  hasRecentGRData: boolean;
  hasGRConsent: boolean;
  hasOldGRConsent: boolean;
  fetchGRData: () => void;
  grDataLoading: boolean;
  consentGR: () => void;
  consentGRLoading: boolean;
  grDataFade: boolean;
  idCheck: () => void;
}

const CustomerDataContext = createContext<CustomerDataProps>(null);

export const CustomerDataProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();
  const { isAuthenticated } = useAuth();
  const { country } = useSettings();

  const [customerData, setCustomerData] = useState<GrApplicantType>(null);
  const [dataLoading, setDataLoading] = useState({
    done: false,
    ongoing: false,
  });

  const [grDataLoading, setGrDataLoading] = useState(false);
  const [grDataFade, setGrDataFade] = useState(false);

  const websiteVisitCallback = useWebsiteVisitCallback();

  useEffect(() => {
    websiteVisitCallback();
  }, []);

  useEffect(() => {
    if (grDataLoading) return;

    setGrDataFade(true);
    setTimeout(() => setGrDataFade(false), 1000);
  }, [grDataLoading]);

  const getData = useMemo(() => async (requestId = v4()) => {
    setDataLoading((p) => ({ ...p, ongoing: true }));

    try {
      const resp = await api.myPages.getApplicantMyPagesApplicantMeGet(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      let errorDescription = 'Get customer offers';

      if (e.status === 404) errorDescription = '404 Customer not found - Get customer offers';

      logger.error(e, { source: 'CreditCardOffersContext', description: errorDescription, requestId });
      Sentry.captureException(e);
    }
    setDataLoading((p) => ({ ...p, ongoing: false, done: true }));
  }, [api]);

  useEffect(() => {
    if (!isAuthenticated) return () => { };

    const requestId = v4();
    getData(requestId);

    return () => {
      api.abortRequest(requestId);
    };
  }, [getData, isAuthenticated]);

  const consentGR = useMemo(() => async () => {
    setGrDataLoading(true);

    const requestId = v4();

    try {
      const resp = await api.myPages.debtRegisterConsentMyPagesDebtRegisterConsentPost(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data);
    } catch (e) {
      logger.error(e, { source: 'CustomerDataContext', description: 'Consent to Gjeldsregisteret', requestId });
      Sentry.captureException(e);
    }

    setGrDataLoading(false);
    getData(); // This is the line that we want to remove but better safe than sorry!
  }, [api, country, getData]);

  const fetchGRData = useMemo(() => async () => {
    setGrDataLoading(true);

    const requestId = v4();

    try {
      const resp = await api.myPages.debtRegisterRenewMyPagesDebtRegisterRenewPost(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data);
    } catch (e) {
      logger.error(e, { source: 'CustomerDataContext', description: 'Fetch Gjeldsregisteret data', requestId });
      Sentry.captureException(e);
    }

    setGrDataLoading(false);
  }, [api, country]);

  const checkGRConsent = useMemo(() => async () => {
    setDataLoading((p) => ({ ...p, ongoing: true }));

    const requestId = v4();

    try {
      const resp = await api.myPages.debtRegisterCheckConsentMyPagesDebtRegisterCheckConsentPost(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setCustomerData(resp.data);
    } catch (e) {
      logger.error(e, { source: 'CustomerDataContext', description: 'Check Gjeldsregisteret consent', requestId });
      Sentry.captureException(e);
    }

    setDataLoading((p) => ({ ...p, ongoing: false, done: true }));
  }, [api, country]);

  const idCheck = useMemo(() => async () => {
    const requestId = v4();

    try {
      const resp = await api.myPages.idCheckMyPagesIdCheckGet(
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );
      setCustomerData((resp.data as any) as ApplicantNO | ApplicantSE);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      logger.error(e, { source: 'CustomerDataContext', description: 'ID check', requestId });
      Sentry.captureException(e);
    }
  }, [api]);

  const value: CustomerDataProps = useMemo(() => {
    let hasGRConsent = false;
    let hasOldGRConsent = false;
    let hasRecentGRData = false;
    let lastExternallyVerifiedCredits: Date = null;
    let externallyVerifiedCredits: ApplicantNO['externally_verified_credits'] = [];

    if (customerData?.country === Country.NO) {
      hasGRConsent = (customerData as ApplicantNO)?.gr_consent ?? false;
      const lastExternallyVerifiedCreditsConsent = (customerData as ApplicantNO).last_externally_verified_credits_consent;
      lastExternallyVerifiedCredits = (customerData as ApplicantNO).last_externally_verified_credits ? new Date(customerData.last_externally_verified_credits) : null;
      const askForNewConsentLimitDays = Number(process.env.REACT_APP_GR_NEW_CONSENT_LIMIT_DAYS) || 90;

      if (hasGRConsent && parseDate().diff(parseDate(lastExternallyVerifiedCreditsConsent), 'days') > askForNewConsentLimitDays) hasOldGRConsent = true;

      hasRecentGRData = lastExternallyVerifiedCredits && lastExternallyVerifiedCredits > new Date(Date.now() - 1000 * 60 * 60 * 24 * 10);
      externallyVerifiedCredits = customerData?.country === Country.NO ? customerData?.externally_verified_credits ?? [] : [];
    } else if (customerData?.country && country !== customerData?.country) console.error('Country mismatch in CustomerDataContext');

    return ({
      getData,
      dataLoading: dataLoading.ongoing,
      dataLoaded: dataLoading.done,
      data: customerData,
      lastExternallyVerifiedCredits,
      externallyVerifiedCredits,
      hasRecentGRData,
      hasGRConsent,
      hasOldGRConsent,
      fetchGRData,
      grDataLoading,
      consentGR,
      consentGRLoading: dataLoading.ongoing,
      checkGRConsent,
      grDataFade,
      idCheck,
    });
  }, [customerData, getData, dataLoading, dataLoading, checkGRConsent, consentGR, fetchGRData, grDataLoading, grDataFade, country]);

  return (
    <CustomerDataContext.Provider value={value}>
      {children}
    </CustomerDataContext.Provider>
  );
};

export const useCustomerData = (): CustomerDataProps => useContext(CustomerDataContext);

export default CustomerDataContext;
