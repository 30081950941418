import { createContext, useMemo } from 'react';

import { Api as ADApi } from 'src/api/anomaly_detector';
import { Api as AMApi } from 'src/api/apps_mgr_blanco';
import { Api as KApi } from 'src/api/kdlt';
import { Api as MLEAPI } from 'src/api/ml_engine';
import { Api as ZApi } from 'src/api/zaker';
import { Api } from 'src/api/zrm';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import useUser from 'src/hooks/useUser';
import { ZensUser } from 'src/types/auth';
import { externalAuthPlatforms } from 'src/utils/auth/authPlatforms';

type BearerToken = string;
export type ZRMApi = Api<BearerToken>;
export type ZakerAPI = ZApi<BearerToken>;
export type KDLTApi = KApi<BearerToken>;
export type MLEngineApi = MLEAPI<BearerToken>;
export type AppsMgrApi = AMApi<BearerToken>;
export type AnomalyDetectorApi = ADApi<BearerToken>;

interface APIs {
  api: ZRMApi,
  zakerApi: ZakerAPI,
  mlEngineApi: MLEngineApi,
  kdltApi: KDLTApi,
  appsMgrBlancoApi: AppsMgrApi,
  anomalyDetectorApi: AnomalyDetectorApi,
}

const APIContext = createContext<APIs>(null);

export const APIProvider = ({ children }) => {
  const { getAccessToken, platform: authPlatform, isAuthenticated } = useAuth();
  const { settings } = useSettings();
  const user = useUser() as ZensUser;

  const value = useMemo((): APIs => {
    const securityWorker = async () => getAccessToken().then((token) => ({ headers: ({ Authorization: `Bearer ${token}` }) }));
    const externalSecurityWorker = () => {
      if (!isAuthenticated) return async () => ({ headers: ({}) });

      return securityWorker;
    };
    // Use zoordinate from settings only if it matches zoordinate assigned
    // to the user account.
    let zoordinates = user?.zoordinates?.[0];

    if (user?.zoordinates?.includes(settings.zoordinates)) zoordinates = settings.zoordinates;

    const baseApiParams = { secure: true, headers: authPlatform === AuthenticationPlatform.Auth0 ? ({ 'x-zoordinates': zoordinates }) : ({}) };

    // const baseApiParams = { secure: true, headers: authPlatform === AuthenticationPlatform.Auth0 ? ({ 'x-zoordinates': settings.zoordinates || (user as ZensUser)?.zoordinates?.[0] }) : ({}) };
    return {
      api: new Api({
        baseUrl: window.location.hostname.endsWith('zensum.no') ? process.env.REACT_APP_ZRM_URL_NO : process.env.REACT_APP_ZRM_URL,
        securityWorker: externalAuthPlatforms.includes(authPlatform) ? externalSecurityWorker() : securityWorker,
        baseApiParams: { ...baseApiParams, credentials: 'include' },
      }),
      zakerApi: authPlatform === AuthenticationPlatform.Auth0 && new ZApi({
        baseUrl: process.env.REACT_APP_ZAKER_URL,
        securityWorker,
        baseApiParams,
      }),
      kdltApi: new KApi({
        baseUrl: process.env.REACT_APP_KDLT_URL,
        securityWorker,
        baseApiParams,
      }),
      mlEngineApi: authPlatform === AuthenticationPlatform.Auth0 && new MLEAPI({
        baseUrl: process.env.REACT_APP_ML_ENGINE_URL,
        securityWorker,
        baseApiParams,
      }),
      appsMgrBlancoApi: authPlatform === AuthenticationPlatform.Auth0 && new AMApi({
        baseUrl: process.env.REACT_APP_APPS_MGR_BLANCO_URL,
        securityWorker,
        baseApiParams,
      }),
      anomalyDetectorApi: authPlatform === AuthenticationPlatform.Auth0 && new ADApi({
        baseUrl: process.env.REACT_APP_ANOMALY_DETECTOR_URL,
        securityWorker,
        baseApiParams,
      }),
    };
  }, [settings.zoordinates, getAccessToken, user, authPlatform]);

  return (
    <APIContext.Provider value={value}>
      {children}
    </APIContext.Provider>
  );
};

export default APIContext;
